import * as React from 'react'

import { Layout } from "../components/_layout"
import { Contact } from "../components/ContactModule"
import { Newsletter } from "../components/homepage"

const Contacto: React.FC = () => {
  return (
    <Layout withNav seoTitle="Contacto" seoDescription="Si tienes alguna duda contáctanos">
      <Contact />
      <Newsletter sectionID={'about-newsletter'} />
    </Layout>
  )
}

export default Contacto
